import pRetry from "p-retry";
import {
  CreateLogsInput,
  LogType,
  LogLevel,
  CountMap,
  Event,
  Exposure,
  LocalLogger,
  RemoteLogger,
} from "../types";
import toDimensionTypeEnum from "./toDimensionTypeEnum";
import uniqueId from "./uniqueId";
import getMetadata from "./getMetadata";

export default class BackendRemoteLogger implements RemoteLogger {
  private readonly token: string;
  private readonly queue: {
    logs: NonNullable<CreateLogsInput["logs"]>;
    evaluations: Map<
      /* Commit ID */ string,
      Map</* Expression ID */ string, number>
    >;
    events: NonNullable<CreateLogsInput["events"]>;
    exposures: NonNullable<CreateLogsInput["exposures"]>;
  } = {
    logs: [],
    evaluations: new Map(),
    events: [],
    exposures: [],
  };
  private readonly createLogs: (
    traceId: string,
    input: CreateLogsInput
  ) => Promise<void>;
  private readonly localLogger: LocalLogger;

  constructor({
    token,
    createLogs,
    localLogger,
  }: {
    token: string;
    createLogs: (traceId: string, input: CreateLogsInput) => Promise<void>;
    localLogger: LocalLogger;
  }) {
    this.token = token;
    this.createLogs = createLogs;
    this.localLogger = localLogger;
  }

  // eslint-disable-next-line max-params
  public log(
    type: LogType,
    level: LogLevel,
    commitId: string | null,
    message: string,
    metadata: object
  ): void {
    const { queue } = this;

    // Avoid memory leak in case we generate lots of logs or fail to flush them.
    if (queue.logs.length > 1000) {
      this.localLogger(
        LogLevel.Debug,
        "Ignoring log, as more than 1000 in the queue already.",
        /* metadata */ {}
      );
      return;
    }

    queue.logs.push({
      commitId,
      type,
      level,
      message,
      metadataJson: JSON.stringify(metadata),
      createdAt: new Date().toJSON(),
    });
  }

  public evaluations(commitId: string, evaluations: CountMap): void {
    const { queue } = this;

    let maybeCommitMap = queue.evaluations.get(commitId);
    if (!maybeCommitMap) {
      maybeCommitMap = new Map();
      queue.evaluations.set(commitId, maybeCommitMap);
    }
    const commitMap = maybeCommitMap;

    Object.entries(evaluations).forEach(([expressionId, count]) => {
      commitMap.set(expressionId, (commitMap.get(expressionId) ?? 0) + count);
    });
  }

  public events(commitId: string, events: CountMap): void {
    const { queue } = this;

    Object.entries(events).forEach(([eventJson]) => {
      const { eventObjectTypeName, eventPayload } = JSON.parse(
        eventJson
      ) as Event;

      queue.events.push({
        commitId,
        eventObjectTypeName,
        eventPayloadJson: JSON.stringify(eventPayload),
        createdAt: new Date().toJSON(),
      });
    });
  }

  public exposures(commitId: string, exposures: CountMap): void {
    const { queue } = this;

    Object.entries(exposures).forEach(([exposureJson]) => {
      const { splitId, unitId, assignment, eventObjectTypeName, eventPayload } =
        JSON.parse(exposureJson) as Exposure;

      queue.exposures.push({
        commitId,
        splitId,
        unitId,
        assignment: Object.entries(assignment).map(([dimensionId, entry]) => ({
          dimensionId,
          entryType: toDimensionTypeEnum(entry.type),
          ...(entry.type === "discrete"
            ? { discreteArmId: entry.armId }
            : { continuousValue: entry.value }),
        })),
        eventObjectTypeName,
        eventPayloadJson: eventPayload ? JSON.stringify(eventPayload) : null,
        createdAt: new Date().toJSON(),
      });
    });
  }

  public async flush(traceId: string): Promise<void> {
    const { queue, token, createLogs, localLogger: localLog } = this;

    const anythingToFlush =
      queue.logs.length > 0 ||
      queue.evaluations.size > 0 ||
      queue.events.length > 0 ||
      queue.exposures.length > 0;

    if (!anythingToFlush) {
      return;
    }

    // Only include 1 random log to ensure the payload doesn't get too big
    const randomLog =
      queue.logs.length > 0
        ? queue.logs[Math.floor(queue.logs.length * Math.random())]
        : null;

    const createLogsInput: CreateLogsInput = {
      token,
      idempotencyKey: uniqueId(),
      logs: randomLog ? [randomLog] : [],
      evaluations: [...queue.evaluations.entries()].flatMap(
        ([commitId, commitMap]) =>
          [...commitMap.entries()].map(([expressionId, count]) => ({
            commitId,
            expressionId,
            count,
          }))
      ),
      events: queue.events,
      exposures: queue.exposures,
    };

    queue.logs = [];
    queue.evaluations.clear();
    queue.events = [];
    queue.exposures = [];

    try {
      await pRetry(
        (attemptNumber) => {
          localLog(
            LogLevel.Debug,
            `Attempt ${attemptNumber} to flush logs to backend...`,
            /* metadata */ { traceId, createLogsInput }
          );
          return createLogs(traceId, createLogsInput);
        },
        {
          onFailedAttempt: (error) => {
            localLog(
              LogLevel.Error,
              `Attempt ${error.attemptNumber} to flush logs failed. There are ${error.retriesLeft} retries left.`,
              { traceId, ...getMetadata(error), createLogsInput }
            );
          },
        }
      );

      localLog(
        LogLevel.Debug,
        "Successfully flushed logs to backend.",
        /* metadata */ { traceId, createLogsInput }
      );
    } catch (error) {
      localLog(
        LogLevel.Error,
        "All attempts to flush logs failed. These logs will be lost.",
        { traceId, ...getMetadata(error), createLogsInput }
      );
    }
  }
}
