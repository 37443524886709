/* eslint-disable no-underscore-dangle */
import {
  evaluate,
  nullThrows,
  prefixError,
  getEvaluationLogs,
  mergeLogs,
  Expression,
  Fragment,
  ObjectValue,
  Query,
  Selection,
  Step,
  Value,
  breakingSchemaChangesError,
  UpdateListener,
  ReductionLogs,
  asError,
  DehydratedState,
  DeepPartial,
  LogLevel,
  uniqueId,
  ObjectValueWithVariables,
} from "../shared";
import getMetadata from "../shared/helpers/getMetadata";
import mergeValueAndOverride from "../shared/helpers/mergeValueAndOverride";
import throwIfObjectValueIsInvalid from "../shared/helpers/throwIfObjectValueIsInvalid";
import Context from "./Context";
import Logger from "./Logger";
import getLocalLogArguments from "./getLocalLogArguments";
import getNodeCacheKey from "./getNodeCacheKey";
import getNodePath from "./getNodePath";

export type Props = {
  readonly context: Context | null;
  readonly logger: Logger | null;
  readonly parent: Node | null;
  readonly step: Step | null;
  readonly expression: Expression | null;
};

export default class Node {
  // @internal
  public props: Props;
  // @internal
  public initDataHash: string | null;
  // This should be overridden by subclasses as the constructor name may be
  // minified and mangled by bundlers
  public typeName: string = this.constructor.name;

  constructor(props: Props) {
    this.props = props;
    this.initDataHash = this.props.context?.initData?.hash ?? null;
  }

  protected updateIfNeeded(): void {
    const { context, parent, step } = this.props;

    if (!context || !context.initData) {
      return;
    }

    // If we're on the latest commit hash, we don't need to update
    if (this.initDataHash === context.initData.hash) {
      return;
    }

    // If we don't have a parent, we're the root node
    if (!parent) {
      this.props = {
        ...this.props,
        expression: context.initData.reducedExpression,
      };
      this.initDataHash = context.initData.hash;
      return;
    }

    // Update from the parent
    if (!step) {
      throw new Error("Node update error: Missing step.");
    }

    switch (step.type) {
      case "GetFieldStep": {
        this.props = parent.getField(step.fieldName, step.fieldArguments);
        this.initDataHash = parent.initDataHash;
        break;
      }
      case "GetItemStep": {
        const newProps: Props | undefined = parent._getItems(
          step.fallbackLength
        )[step.index];

        if (!newProps) {
          throw new Error("Node update error: No item props.");
        }

        this.props = newProps;
        this.initDataHash = parent.initDataHash;
        break;
      }
      default: {
        const neverStep: never = step;
        throw new Error(`Unexpected step: ${JSON.stringify(neverStep)}`);
      }
    }
  }

  protected getField(fieldName: string, fieldArguments: ObjectValue): Props {
    const step: Step = { type: "GetFieldStep", fieldName, fieldArguments };
    const { context } = this.props;
    const initDataHash = context?.initData?.hash;

    if (!initDataHash || !context.getFieldCache) {
      // No caching if the sdk hasn't been initialized or there is no cache.
      return this.createProps(
        step,
        this.getReducedFieldExpression(fieldName, fieldArguments)
      );
    }

    const cacheKey = getNodeCacheKey(
      initDataHash,
      getNodePath(/* parent */ this, step),
      /* suffix */ ""
    );
    const cachedReducedFieldExpression = context.getFieldCache.get(cacheKey);
    if (cachedReducedFieldExpression) {
      return this.createProps(step, cachedReducedFieldExpression);
    }

    const reducedFieldExpression = this.getReducedFieldExpression(
      fieldName,
      fieldArguments
    );
    if (reducedFieldExpression) {
      context.getFieldCache.set(cacheKey, reducedFieldExpression);
    }
    return this.createProps(step, reducedFieldExpression);
  }

  // @internal
  private getReducedFieldExpression(
    fieldName: string,
    fieldArguments: ObjectValue
  ): Expression | null {
    try {
      prefixError(
        () => throwIfObjectValueIsInvalid(fieldArguments),
        "Invalid field arguments: "
      );

      this.updateIfNeeded();

      const { expression } = this.props;

      if (!expression) {
        this.log(
          LogLevel.Debug,
          `Using fallback for field "${fieldName}" as expression is null. This is expected before initialization.`
        );
        return null;
      }

      if (expression.type !== "ObjectExpression") {
        throw new Error(
          `Cannot get field "${fieldName}" as expression type is "${expression.type}".`
        );
      }

      const context = nullThrows(
        this.props.context,
        `Cannot get field "${fieldName}" as context is null.`
      );

      const selection: Selection<ObjectValue> = {
        [fieldName]: { fieldArguments, fieldQuery: null },
      };
      const { objectTypeName } = expression;
      const fragment: Fragment<ObjectValue> = { objectTypeName, selection };
      const query: Query<ObjectValue> = { [objectTypeName]: fragment };

      const reducedObjectExpression = context.reduce(query, expression);
      if (reducedObjectExpression.type !== "ObjectExpression") {
        throw new Error(
          `Cannot get field "${fieldName}" as reduced expression type is "${expression.type}".`
        );
      }

      const reducedFieldExpression = nullThrows(
        reducedObjectExpression.fields[fieldName],
        `Object expression does not contain field "${fieldName}".`
      );
      reducedFieldExpression.logs = mergeLogs(
        reducedObjectExpression.logs,
        getEvaluationLogs(reducedObjectExpression),
        reducedFieldExpression.logs
      );

      return reducedFieldExpression;
    } catch (error) {
      this.log(
        LogLevel.Error,
        `Error getting field "${fieldName}" with arguments ${JSON.stringify(fieldArguments)}: ${asError(error).message}`,
        getMetadata(error)
      );
      return null;
    }
  }

  _getItems(fallbackLength: number): Props[] {
    const { context, parent, step } = this.props;
    const initDataHash = context?.initData?.hash;

    if (!initDataHash || !context.getItemsCache) {
      // No caching if the sdk hasn't been initialized or there is no cache.
      return this.createPropsArray(this._getItemExpressions(), fallbackLength);
    }

    const cacheKey = getNodeCacheKey(
      initDataHash,
      getNodePath(parent, step),
      /* suffix */ ""
    );
    const cachedItemExpressions = context.getItemsCache.get(cacheKey);
    if (cachedItemExpressions) {
      return this.createPropsArray(cachedItemExpressions, fallbackLength);
    }

    const itemExpressions = this._getItemExpressions();
    if (itemExpressions) {
      context.getItemsCache.set(cacheKey, itemExpressions);
    }
    return this.createPropsArray(itemExpressions, fallbackLength);
  }

  // @internal
  private _getItemExpressions(): Expression[] | null {
    try {
      this.updateIfNeeded();

      const { expression } = this.props;

      if (!expression) {
        this.log(
          LogLevel.Debug,
          "Using fallback for array items as expression is null. This is expected before initialization."
        );
        return null;
      }

      if (expression.type !== "ListExpression") {
        throw new Error(
          `Cannot get items as expression type is "${expression.type}". ${breakingSchemaChangesError}`
        );
      }

      const listLogs = mergeLogs(
        expression.logs,
        getEvaluationLogs(expression)
      );

      const result: Expression[] = expression.items.map((item, index) => {
        const itemExpression = nullThrows(
          item,
          `List expression has null item at index ${index}.`
        );
        itemExpression.logs = mergeLogs(listLogs, itemExpression.logs);

        return itemExpression;
      });

      return result;
    } catch (error) {
      this.log(
        LogLevel.Error,
        `Error getting items: ${asError(error).message}`,
        getMetadata(error)
      );
      return null;
    }
  }

  protected evaluate(query: Query<ObjectValue> | null, fallback: Value): Value {
    const valueAndLogs = this.getValueAndLogsWithCache(query);

    if (!valueAndLogs) {
      return fallback;
    }

    const { value: valueWithoutOverride, logs: reductionLogs } = valueAndLogs;

    const value = mergeValueAndOverride(
      valueWithoutOverride,
      this.getNodeOverride()
    );

    this.log(
      LogLevel.Debug,
      `Evaluated to ${JSON.stringify(value)}`,
      /* metadata */ { query },
      reductionLogs
    );

    return value;
  }

  private getNodeOverride(): any {
    const { context, parent, step } = this.props;

    if (!context) {
      return null;
    }

    if (context.override === null || context.override === undefined) {
      // Short-circuit if no override in context
      return null;
    }

    if (!parent) {
      // We're the Query node
      return context.override ?? null;
    }

    if (!step) {
      return null;
    }

    const parentOverride = parent.getNodeOverride();

    if (parentOverride === null || parentOverride === undefined) {
      return null;
    }

    if (step.type === "GetFieldStep") {
      return (parentOverride as ObjectValue)[step.fieldName] ?? null;
    }

    return (parentOverride as Value[])[step.index] ?? null;
  }

  // @internal
  protected getValueAndLogsWithCache(
    query: Query<ObjectValue> | null
  ): { value: Value; logs: ReductionLogs } | null {
    const { context, parent, step } = this.props;
    const initDataHash = context?.initData?.hash;

    if (!initDataHash || !context.evaluateCache) {
      // No caching if the sdk hasn't been initialized or there is no cache.
      return this.getValueAndLogs(query);
    }

    const cacheKey = getNodeCacheKey(
      initDataHash,
      getNodePath(parent, step),
      /* suffix */ JSON.stringify(query)
    );
    const cachedValueAndLogs = context.evaluateCache.get(cacheKey);
    if (cachedValueAndLogs) {
      return cachedValueAndLogs;
    }

    const valueAndLogs = this.getValueAndLogs(query);
    if (valueAndLogs) {
      context.evaluateCache.set(cacheKey, valueAndLogs);
    }
    return valueAndLogs;
  }

  // @internal
  private getValueAndLogs(
    query: Query<ObjectValue> | null
  ): { value: Value; logs: ReductionLogs } | null {
    try {
      this.updateIfNeeded();
      const { expression } = this.props;

      if (!expression) {
        this.log(
          LogLevel.Debug,
          `Using fallback while evaluating as expression is null. This is expected before initialization.`
        );
        return null;
      }

      const context = nullThrows(
        this.props.context,
        "Cannot evaluate as context is null."
      );

      const reducedExpression = context.reduce(query, expression);

      return prefixError(
        () => evaluate(reducedExpression),
        "Evaluation error: "
      );
    } catch (error) {
      this.log(
        LogLevel.Error,
        `Error getting value and logs: ${asError(error).message}`,
        getMetadata(error)
      );
      return null;
    }
  }

  // @internal
  private createProps(step: Step, expression: Expression | null): Props {
    const { context, logger } = this.props;
    return { step, parent: this, context, expression, logger };
  }

  // @internal
  private createPropsArray(
    itemExpressions: Expression[] | null,
    fallbackLength: number
  ): Props[] {
    return (itemExpressions || Array(fallbackLength).fill(null)).map(
      (expression, index) =>
        this.createProps(
          { type: "GetItemStep", index, fallbackLength },
          expression
        )
    );
  }

  _logUnexpectedTypeError(): void {
    if (!this.props.expression) {
      this.log(
        LogLevel.Debug,
        `Unexpected expression type as expression is null but this is expected before initialization.`
      );
      return;
    }

    this.log(LogLevel.Error, "Unexpected expression type.");
  }

  protected logUnexpectedValueError(value: Value): void {
    this.log(
      LogLevel.Error,
      `Evaluated to unexpected value: ${JSON.stringify(value)}`
    );
  }

  private log(
    level: LogLevel,
    message: string,
    metadata: object = {},
    reductionLogs: ReductionLogs | null = null
  ): void {
    const { typeName, initDataHash } = this;
    const { parent, step, logger, expression } = this.props;
    const commitId = this.props.context?.initData?.commitId.toString() ?? null;

    const nodePath = getNodePath(parent, step);

    if (!logger) {
      // eslint-disable-next-line no-console
      console.error(
        ...getLocalLogArguments(
          `No logger for ${typeName}Node at ${nodePath} to log message: ${message}`,
          { message, reductionLogs, ...metadata }
        )
      );
      return;
    }

    logger.nodeLog({
      commitId,
      initDataHash,
      nodeTypeName: typeName,
      nodePath,
      nodeExpression: expression,
      level,
      message,
      metadata,
      reductionLogs,
    });
  }

  getStateHash(): string | null {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot get state hash.");
      return null;
    }
    return context.getStateHash();
  }

  addUpdateListener(listener: UpdateListener): void {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot add update listener.");
      return;
    }
    context.addUpdateListener(listener);
  }

  removeUpdateListener(listener: UpdateListener): void {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot remove update listener.");
      return;
    }
    context.removeUpdateListener(listener);
  }

  /**
   * Initialize from the init data provider if needed
   */
  initIfNeeded(traceId = uniqueId()): Promise<void> {
    const { context } = this.props;
    if (!context) {
      this.log(
        LogLevel.Error,
        "No context so cannot initialize from the data provider."
      );
      return Promise.resolve();
    }
    return context.initIfNeeded(traceId);
  }

  /**
   * Returns the timestamp of the last time the SDK was initialized from
   * the init data provider
   */
  getLastDataProviderInitTime(): number | null {
    const { context } = this.props;
    if (!context) {
      this.log(
        LogLevel.Error,
        "No context so cannot get the last data provider init time."
      );
      return null;
    }
    return context.lastDataProviderInitTime;
  }

  flushLogs(traceId = uniqueId()): Promise<void> {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot flush logs.");
      return Promise.resolve();
    }
    return context.logger.flush(traceId);
  }

  setOverride<T extends object>(
    override: DeepPartial<T>,
    traceId = uniqueId()
  ): void {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot set override.");
      return;
    }
    context.setOverride<T>(traceId, override);
  }

  dehydrate<Override extends object, VariableValues extends ObjectValue>(
    query?: Query<ObjectValueWithVariables>,
    variableValues?: VariableValues
  ): DehydratedState<Override, VariableValues> | null {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot dehydrate.");
      return null;
    }
    return context.dehydrate(query, variableValues);
  }

  hydrate<Override extends object, VariableValues extends ObjectValue>(
    dehydratedState: DehydratedState<Override, VariableValues>,
    traceId = uniqueId()
  ): void {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot hydrate.");
      return;
    }
    context.hydrate(traceId, dehydratedState);
  }

  close(): void {
    const { context } = this.props;
    if (!context) {
      this.log(LogLevel.Error, "No context so cannot close.");
      return;
    }
    context.shouldClose = true;
  }
}
