/* eslint-disable @typescript-eslint/ban-ts-comment */

export const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

function isNextJsBuild(): boolean {
  return (
    typeof process !== "undefined" &&
    process.env &&
    "__NEXT_DIST_DIR" in process.env
  );
}

// Detect process.browser === false to determine if we're on a NextJS backend.
// We prefer this over `typeof window` because it gives us a positive indicator
// that the process is aware it is a hybrid that could run in the browser,
// rather than detecting non-browser environments generally.
export function isNextJsServer(): boolean {
  return (
    typeof process === "object" &&
    process &&
    // `"browser" in process` returns false in Next.js due to build-time
    // inlining, hence the need for a type assertion rather than type guard.
    (process as { browser?: boolean }).browser === false
  );
}

export const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

// Best guess at whether we're on a backend server
export const isBackendServer =
  !isBrowser && !isNextJsBuild() && !isNextJsServer() && !isReactNative;

// @ts-ignore
const isFastly = !!globalThis.fastly;
// @ts-ignore
const isEdgeLight = !!globalThis.EdgeRuntime;
// @ts-ignore
const isNetlify = !!globalThis.Netlify;
const isWorkerd = globalThis.navigator?.userAgent === "Cloudflare-Workers";

export const isServerless = isFastly || isEdgeLight || isNetlify || isWorkerd;
