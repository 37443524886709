import { Value } from "../types";

export default function mergeValueAndOverride(
  value: Value,
  override: any
): Value {
  if (override === null || override === undefined) {
    return value; // No override specified so we just return the value.
  }
  if (Array.isArray(value) && Array.isArray(override)) {
    // No null or undefined values indicate that we should override the list length.
    const overrideLength = !override.some(
      (item) => item === undefined || item === null
    );
    if (overrideLength) {
      return override.map((itemOverride, index) =>
        mergeValueAndOverride(value[index], itemOverride)
      );
    }
    // Add extra items to the end in case override array is longer than value array.
    return (
      [...value, ...override.slice(value.length)]
        .map((itemValue, index) =>
          mergeValueAndOverride(itemValue, override[index])
        )
        // Remove any null or undefined values that are beyond the original list length.
        .filter((item) => item !== null && item !== undefined)
    );
  }
  if (typeof value === "object" && typeof override === "object") {
    return Object.fromEntries(
      Object.entries(value).map(([fieldName, field]) => [
        fieldName,
        mergeValueAndOverride(field, override[fieldName]),
      ])
    );
  }
  return override;
}
