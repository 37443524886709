import { sdkVersion } from "../shared";

export default function getLocalLogArguments(
  message: string,
  metadata: object
): any[] {
  return [
    `[Hypertune] [v${sdkVersion}] ${message}`,
    ...(Object.keys(metadata).length > 0 ? [metadata] : []),
  ];
}
