import { InitData, InitDataProvider } from "../../shared/types";

export default class VercelEdgeConfigInitDataProvider
  implements InitDataProvider
{
  private readonly edgeConfigClient: { get: (key: string) => Promise<any> };
  private readonly itemKey: string;

  constructor({
    edgeConfigClient,
    itemKey,
  }: {
    edgeConfigClient: { get: (key: string) => Promise<any> };
    itemKey: string;
  }) {
    this.edgeConfigClient = edgeConfigClient;
    this.itemKey = itemKey;
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return "Vercel Edge Config";
  }

  async getInitData(): Promise<InitData> {
    const response = await this.edgeConfigClient.get(this.itemKey);

    if (!response) {
      throw new Error(`[initFromEdgeConfig] no value for key ${this.itemKey}`);
    }

    if (!response.commitId) {
      throw new Error(
        `[initFromEdgeConfig] unexpected response: ${JSON.stringify(response)}`
      );
    }

    return response;
  }
}
