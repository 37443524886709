import {
  InitData,
  LogLevel,
  TracedFetch,
  LocalLogger,
  ObjectValue,
  InitDataProvider,
} from "../../shared/types";
import newTracedFetch from "../../shared/helpers/newTracedFetch";
import toConsoleFunction from "../../shared/helpers/toConsoleFunction";
import { hashRequest, initRequest } from "../edge";
import getLocalLogArguments from "../getLocalLogArguments";
import { defaultBranchName } from "../../shared";

export default class HypertuneEdgeInitDataProvider implements InitDataProvider {
  private readonly baseUrl: string;
  private readonly token: string;
  private readonly branchName: string | null;
  private readonly tracedFetch: TracedFetch;

  // eslint-disable-next-line max-params
  constructor({
    baseUrl,
    token,
    branchName = null,
    localLogger = defaultLocalLogger,
    timeoutMs = 5_000,
  }: {
    baseUrl: string;
    token: string;
    branchName?: string | null;
    localLogger?: LocalLogger;
    timeoutMs?: number;
  }) {
    if (branchName !== null && branchName !== defaultBranchName) {
      localLogger(
        LogLevel.Warn,
        `using non default branch name: "${branchName}"`,
        {}
      );
    }
    this.baseUrl = baseUrl;
    this.token = token;
    this.branchName = branchName;
    this.tracedFetch = newTracedFetch({
      timeoutMs,
      localLogger,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return "Hypertune Edge";
  }

  getInitData({
    traceId,
    queryCode,
    variableValues,
  }: {
    traceId: string;
    queryCode: string;
    variableValues: ObjectValue;
  }): Promise<InitData> {
    return initRequest({
      traceId,
      query: queryCode,
      variables: variableValues,
      token: this.token,
      branchName: this.branchName,
      edgeBaseUrl: this.baseUrl,
      tracedFetch: this.tracedFetch,
    });
  }

  async getInitDataHash({
    traceId,
    queryCode,
    variableValues,
  }: {
    traceId: string;
    queryCode: string;
    variableValues: ObjectValue;
  }): Promise<string> {
    const { hash } = await hashRequest({
      traceId,
      query: queryCode,
      variables: variableValues,
      token: this.token,
      branchName: this.branchName,
      edgeBaseUrl: this.baseUrl,
      tracedFetch: this.tracedFetch,
    });
    return hash;
  }
}

function defaultLocalLogger(
  level: LogLevel,
  message: string,
  metadata: object
): void {
  if (level === LogLevel.Debug) {
    return;
  }

  toConsoleFunction(level)(...getLocalLogArguments(message, metadata));
}
