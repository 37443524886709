import {
  DimensionType,
  ContinuousDimensionType,
  DiscreteDimensionType,
} from "../types";

export default function toDimensionTypeEnum(
  type: typeof DiscreteDimensionType | typeof ContinuousDimensionType
): DimensionType {
  switch (type) {
    case "discrete":
      return DimensionType.Discrete;
    case "continuous":
      return DimensionType.Continuous;
    default: {
      const neverType: never = type;
      throw new Error(`Unknown dimension type ${neverType}`);
    }
  }
}
