import asError from "./asError";

export default function getMetadata(error: unknown): object {
  const typedError = asError(error);
  return {
    errorName: typedError.name,
    errorMessage: typedError.message,
    errorStack: typedError.stack,
  };
}
