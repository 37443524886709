/* eslint-disable */

import * as sdk from "@hypertune/sdk";

export const queryCode = `id/5994b18a-b2a1-4963-9e77-6edce9d2d9ff`;

export const query = {"Query":{"objectTypeName":"Query","selection":{"root":{"fieldArguments":{"user":{"id":{"__isVariable":true,"name":"user_id"}}},"fieldQuery":{"Root":{"objectTypeName":"Root","selection":{"headline":{"fieldArguments":{},"fieldQuery":null},"flag":{"fieldArguments":{},"fieldQuery":null}}}}}}}};

function mergeQueryAndArgs(
  query: sdk.Query<sdk.ObjectValueWithVariables>,
  queryArgs: sdk.ObjectValueWithVariables | null,
  unwrapObjectArgs = false
): sdk.Query<sdk.ObjectValueWithVariables> {
  return Object.fromEntries(
    Object.entries(query).map(([objectTypeName, fragment]) => {
      const objectArgs = unwrapObjectArgs
        ? queryArgs &&
          queryArgs[objectTypeName] &&
          queryArgs[objectTypeName] instanceof Object
          ? (queryArgs[objectTypeName] as sdk.ObjectValueWithVariables)
          : null
        : queryArgs;

      return [
        objectTypeName,
        {
          objectTypeName,
          selection: Object.fromEntries(
            Object.entries(fragment.selection).map(
              ([fieldName, { fieldQuery }]) => {
                const fieldArgs =
                  objectArgs &&
                  objectArgs[fieldName] &&
                  objectArgs[fieldName] instanceof Object
                    ? (objectArgs[fieldName] as sdk.ObjectValueWithVariables)
                    : null;

                return [
                  fieldName,
                  {
                    fieldArguments: {
                      ...(fieldArgs && fieldArgs.args
                        ? (fieldArgs.args as sdk.ObjectValueWithVariables)
                        : {}),
                    },
                    fieldQuery: fieldQuery
                      ? mergeQueryAndArgs(fieldQuery, fieldArgs, true)
                      : null,
                  },
                ];
              }
            )
          ),
        },
      ];
    })
  );
}
  
export const vercelFlagDefinitions = {"flag":{"options":[{"value":true},{"value":false}],"origin":"https://app.hypertune.com/projects/2484/draft?view=logic&selected_field_path=root%3Eflag"}};

export type Rec = {
  user_id: string;
}

export type Rec2 = {

}

export type Root = {
  headline: string;
  flag: boolean;
}

const rootFallback = {headline:"",flag:false};

export class RootNode extends sdk.Node {
  typeName = "Root" as const;

  get({ fallback = rootFallback as Root}: { fallback?: Root } = {}): Root {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as Root
  }

  /**
   * [Open in UI]({@link https://app.hypertune.com/projects/2484/draft?view=logic&selected_field_path=root%3Eheadline})
   */
  headline({ args = {}, fallback }: { args?: Rec2; fallback: string; }): string {
    const props0 = this.getField("headline", args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === "StringExpression"
    ) {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in UI]({@link https://app.hypertune.com/projects/2484/draft?view=logic&selected_field_path=root%3Eflag})
   */
  flag({ args = {}, fallback }: { args?: Rec2; fallback: boolean; }): boolean {
    const props0 = this.getField("flag", args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === "BooleanExpression"
    ) {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

export type Query = {
  root: Root;
}

const queryFallback = {root:{headline:"",flag:false}};

export class QueryNode extends sdk.Node {
  typeName = "Query" as const;

  get({ fallback = queryFallback as Query}: { fallback?: Query } = {}): Query {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as Query
  }

  root({ args = {} }: { args?: Rec2; } = {}): RootNode {
    const props0 = this.getField("root", args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === "ObjectExpression" &&
      expression0.objectTypeName === "Root"
    ) {
      return new RootNode(props0);
    }

    const node = new RootNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export type VariableValues = Rec;
export type DehydratedState = sdk.DehydratedState<Query, VariableValues>

export function initHypertune({
  token,
  variableValues,
  override,
  ...options
}: { 
  token: string; 
  variableValues: VariableValues;
  override?: sdk.DeepPartial<Query> | null;
} & sdk.InitOptions): QueryNode {
  return sdk.init({
    NodeConstructor: QueryNode,
    token,
    query,
    queryCode,
    variableValues,
    override,
    options,
  });
}