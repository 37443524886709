/* eslint-disable no-console */
import { LogLevel } from "../types";

export default function toConsoleFunction(
  logLevel: LogLevel
): typeof console.log {
  switch (logLevel) {
    case LogLevel.Error:
      return console.error;
    case LogLevel.Warn:
      return console.warn;
    case LogLevel.Info:
      return console.info;
    case LogLevel.Debug:
      return console.debug;
    default: {
      const neverLevel: never = logLevel;
      throw new Error(`Unknown log level: ${neverLevel}`);
    }
  }
}
