import { RemoteLogger } from "../types";

export default function NoopRemoteLogger(): RemoteLogger {
  return {
    log: () => {
      // Noop
    },
    evaluations: () => {
      // Noop
    },
    events: () => {
      // Noop
    },
    exposures: () => {
      // Noop
    },
    flush: () => Promise.resolve(),
  };
}
