/* eslint-disable max-classes-per-file */

import Node from "./Node";

export class VoidNode extends Node {
  typeName = "Void" as const;

  get(options = {}): void {
    const value = this.evaluate(null, "fallback");

    if (value === true) {
      return;
    }

    this.logUnexpectedValueError(value);
  }
}

export class BooleanNode extends Node {
  typeName = "Boolean" as const;

  get({ fallback }: { fallback: boolean }): boolean {
    const value = this.evaluate(null, fallback);

    if (typeof value === "boolean") {
      return value;
    }

    this.logUnexpectedValueError(value);
    return fallback;
  }
}

export class IntNode extends Node {
  typeName = "Int" as const;

  get({ fallback }: { fallback: number }): number {
    const value = this.evaluate(null, fallback);

    if (typeof value === "number" && Number.isInteger(value)) {
      return value;
    }

    this.logUnexpectedValueError(value);
    return fallback;
  }
}

export class FloatNode extends Node {
  typeName = "Float" as const;

  get({ fallback }: { fallback: number }): number {
    const value = this.evaluate(null, fallback);

    if (typeof value === "number") {
      return value;
    }

    this.logUnexpectedValueError(value);
    return fallback;
  }
}

export class StringNode extends Node {
  typeName = "String" as const;

  get({ fallback }: { fallback: string }): string {
    const value = this.evaluate(null, fallback);

    if (typeof value === "string") {
      return value;
    }

    this.logUnexpectedValueError(value);
    return fallback;
  }
}
