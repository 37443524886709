import { CommitConfig, Expression, SplitMap } from "../types";
import { mapExpressionWithResult } from "./fold";

export default function getSplitsAndCommitConfigForExpression(
  expression: Expression,
  splits: SplitMap,
  commitConfig: CommitConfig
): { splits: SplitMap; commitConfig: CommitConfig } {
  const splitIds = getSplitIdsForExpression(expression);

  return {
    splits: Object.fromEntries(
      Object.entries(splits).filter(([splitId]) => splitIds.has(splitId))
    ),
    commitConfig: {
      splitConfig: Object.fromEntries(
        Object.entries(commitConfig.splitConfig).filter(([splitId]) =>
          splitIds.has(splitId)
        )
      ),
    },
  };
}

function getSplitIdsForExpression(expression: Expression): Set<string> {
  return mapExpressionWithResult(
    (expr) => {
      const splitSet = new Set<string>();

      if (expr?.type === "SplitExpression" && expr.splitId) {
        splitSet.add(expr.splitId);
      }
      return { newExpression: expr, mapResult: splitSet };
    },
    (...splitSets) => {
      const resultSplitSet = new Set<string>();
      splitSets.forEach((splitSet) =>
        splitSet.forEach((value) => resultSplitSet.add(value))
      );

      return resultSplitSet;
    },
    expression
  ).mapResult;
}
