import { HypertuneEdgeInitDataProvider } from "@hypertune/sdk";
import env from "../environment";
import { initHypertune } from "../generated/generated";

const hypertune = initHypertune({
  variableValues: {
    user_id: "adam",
  },
  token: env.HYPERTUNE_TOKEN,
  initDataProvider: new HypertuneEdgeInitDataProvider({
    token: env.HYPERTUNE_TOKEN,
    baseUrl: env.HYPERTUNE_EDGE_BASE_URL,
  }),
  remoteLogging: {
    endpointUrl: env.HYPERTUNE_LOGS_ENDPOINT_URL,
  },
});

export default hypertune;
