import {
  localEdgeWorkerBaseUrl,
  localLogsEndpointUrl,
} from "@hypertune/sdk/src/shared";
import { Env } from "./type";

const env: Env = {
  HYPERTUNE_EDGE_BASE_URL: localEdgeWorkerBaseUrl,
  HYPERTUNE_LOGS_ENDPOINT_URL: localLogsEndpointUrl,
  HYPERTUNE_TOKEN: "U2FsdGVkX18CLZrZ6aGIZYy2gLhSNaQdjEjUSVIL7XQ=",
};

export default env;
