import {
  prodEdgeCdnBaseUrl,
  prodLogsEndpointUrl,
} from "@hypertune/sdk/src/shared/constants";
import { Env } from "./type";

const env: Env = {
  HYPERTUNE_EDGE_BASE_URL: prodEdgeCdnBaseUrl,
  HYPERTUNE_LOGS_ENDPOINT_URL: prodLogsEndpointUrl,
  HYPERTUNE_TOKEN: "U2FsdGVkX18CLZrZ6aGIZYy2gLhSNaQdjEjUSVIL7XQ=",
};

export default env;
