import { Step } from "../shared";
import Node from "./Node";

export default function getNodePath(
  parent: Node | null,
  step: Step | null
): string {
  return `${
    parent ? `${getNodePath(parent.props.parent, parent.props.step)} > ` : ""
  }${
    !step
      ? "{}"
      : step.type === "GetFieldStep"
        ? `${step.fieldName}(${JSON.stringify(step.fieldArguments)})`
        : `[${step.index}]`
  }`;
}
