import {
  defaultCacheSize,
  prodLogsEndpointUrl,
  prodEdgeCdnBaseUrl,
} from "../shared";
import {
  InitOptions,
  LogLevel,
  ObjectValue,
  ObjectValueWithVariables,
  Query,
} from "../shared/types";
import Context from "./Context";
import Node, { Props } from "./Node";
import Logger from "./Logger";
import { isBrowser, isServerless } from "./environment";
import getMetadata from "../shared/helpers/getMetadata";
import HypertuneEdgeInitDataProvider from "./initDataProviders/HypertuneEdgeInitDataProvider";

// eslint-disable-next-line max-params
// rename init + anywhere else we have "initialize" => init
export default function init<T extends Node>({
  NodeConstructor,
  token,
  query,
  queryCode,
  variableValues,
  override,
  options,
}: {
  NodeConstructor: new (props: Props) => T;
  token: string;
  query: Query<ObjectValueWithVariables>;
  queryCode: string;
  variableValues: ObjectValue;
  override?: object | null;
  options: InitOptions;
}): T {
  let logger: Logger;

  try {
    logger = new Logger({
      token,
      remoteLoggingMode:
        options.remoteLogging?.mode ?? (isBrowser ? "session" : "normal"),
      remoteLoggingEndpointUrl:
        options.remoteLogging?.endpointUrl ?? prodLogsEndpointUrl,
      localLogger: options.localLogger,
    });
  } catch (error) {
    if (options.localLogger) {
      options.localLogger(
        LogLevel.Error,
        "Pre-initialize error.",
        getMetadata(error)
      );
    } else {
      // eslint-disable-next-line no-console
      console.error("[Hypertune] Pre-initialize error.", error);
    }
    return new NodeConstructor({
      context: null,
      logger: null,
      parent: null,
      step: null,
      expression: null,
    });
  }

  try {
    const context = new Context({
      query,
      queryCode,
      variableValues,
      initData: options.initData ?? null,
      initDataProvider:
        options.initDataProvider !== undefined
          ? options.initDataProvider
          : new HypertuneEdgeInitDataProvider({
              token,
              baseUrl: prodEdgeCdnBaseUrl,
              branchName: options.branchName,
              localLogger: options.localLogger,
            }),
      initIntervalMs: options.initIntervalMs ?? 1_000,
      logger,
      loggerFlushIntervalMs:
        // Use provided option or otherwise disable flushing in
        // serverless environments or default to 1 second.
        options.remoteLogging?.flushIntervalMs ?? isServerless ? 0 : 1_000,
      cacheSize: options.cacheSize ?? defaultCacheSize,
      override: override ?? null,
    });

    return new NodeConstructor({
      context,
      logger,
      parent: null,
      step: null,
      expression: context.initData?.reducedExpression ?? null,
    });
  } catch (error) {
    logger.log(
      LogLevel.Error,
      /* commitId */ null,
      "Initialize error.",
      getMetadata(error)
    );
    return new NodeConstructor({
      context: null,
      logger,
      parent: null,
      step: null,
      expression: null,
    });
  }
}
