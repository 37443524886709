import {
  ReductionLogs,
  Expression,
  sdkVersion,
  LocalLogger,
  uniqueId,
  CreateLogsInput,
  LogLevel,
  LogType,
  TracedFetch,
} from "../shared";
import { RemoteLogger, RemoteLoggingMode } from "../shared/types";
import BackendRemoteLogger from "../shared/helpers/BackendRemoteLogger";
import toConsoleFunction from "../shared/helpers/toConsoleFunction";
import LRUCache from "../shared/helpers/LRUCache";
import getLocalLogArguments from "./getLocalLogArguments";
import getNodeCacheKey from "./getNodeCacheKey";
import newTracedFetch from "../shared/helpers/newTracedFetch";
import NoopRemoteLogger from "../shared/helpers/NoopRemoteLogger";

const fetchMaxKeepAliveRequestSizeBytes = 64_000;

export default class Logger {
  public readonly id: string;
  private readonly remoteLoggingMode: "normal" | "session";
  private readonly remoteLogCache: LRUCache<boolean> = new LRUCache(10_000);
  private readonly remoteLogger: RemoteLogger;
  private readonly localLogger: LocalLogger;

  constructor({
    token,
    remoteLoggingMode,
    remoteLoggingEndpointUrl,
    localLogger,
  }: {
    token: string;
    remoteLoggingMode: RemoteLoggingMode;
    remoteLoggingEndpointUrl: string;
    localLogger?: LocalLogger;
  }) {
    this.id = uniqueId();
    this.remoteLoggingMode =
      remoteLoggingMode === "off" ? "normal" : remoteLoggingMode;

    this.localLogger = wrapLocalLogger(this.id, localLogger);

    this.remoteLogger =
      remoteLoggingMode === "off"
        ? NoopRemoteLogger()
        : new BackendRemoteLogger({
            token,
            createLogs: getCreateLogsFunction(
              newTracedFetch({
                timeoutMs: 20_000,
                localLogger: this.localLogger,
              }),
              remoteLoggingEndpointUrl
            ),
            localLogger: this.localLogger,
          });
  }

  public nodeLog({
    commitId,
    initDataHash,
    nodeTypeName,
    nodePath,
    nodeExpression,
    level,
    message,
    metadata,
    reductionLogs,
  }: {
    commitId: string | null;
    initDataHash: string | null;
    nodeTypeName: string;
    nodePath: string;
    nodeExpression: Expression | null;
    level: LogLevel;
    message: string;
    metadata: object;
    reductionLogs: ReductionLogs | null;
  }): void {
    const logMessage = `${nodeTypeName}Node at ${nodePath}: ${message}`;
    const logMetadata = {
      sdkVersion,
      nodeTypeName,
      nodePath,
      nodeExpression,
      reductionLogs,
      ...metadata,
    };
    this.localLogger(level, logMessage, logMetadata);

    if (
      (level === LogLevel.Warn || level === LogLevel.Error) &&
      this.shouldRemoteLog(initDataHash, nodePath, message)
    ) {
      this.remoteLogger.log(
        LogType.SdkNode,
        level,
        commitId,
        logMessage,
        logMetadata
      );
    }

    if (reductionLogs) {
      if (!commitId) {
        const errorMessage = `${nodeTypeName}Node at ${nodePath}: Missing commitId so cannot remote log evaluations, events and exposures.`;
        this.localLogger(LogLevel.Error, errorMessage, logMetadata);
        if (this.shouldRemoteLog(initDataHash, nodePath, message)) {
          this.remoteLogger.log(
            LogType.SdkNode,
            level,
            commitId,
            errorMessage,
            logMetadata
          );
        }
        return;
      }
      if (this.shouldRemoteLog(initDataHash, nodePath, "evaluations")) {
        this.remoteLogger.evaluations(commitId, reductionLogs.evaluations);
      }

      // We always log events to the backend
      this.remoteLogger.events(commitId, reductionLogs.events);

      if (this.shouldRemoteLog(initDataHash, nodePath, "exposures")) {
        this.remoteLogger.exposures(commitId, reductionLogs.exposures);
      }
    }
  }

  private shouldRemoteLog(
    initDataHash: string | null,
    nodePath: string,
    cacheKeySuffix: string
  ): boolean {
    switch (this.remoteLoggingMode) {
      case "session": {
        const cacheKey = getNodeCacheKey(
          initDataHash ?? "",
          nodePath,
          cacheKeySuffix
        );

        if (this.remoteLogCache.get(cacheKey)) {
          this.localLogger(
            LogLevel.Debug,
            `Remote log cache hit.`,
            /* metadata */ { initDataHash, nodePath, cacheKeySuffix }
          );
          return false;
        }

        this.remoteLogCache.set(cacheKey, true);
        return true;
      }

      case "normal": {
        return true;
      }

      default: {
        const neverLoggingMode: never = this.remoteLoggingMode;
        throw new Error(`Unexpected logging mode: ${neverLoggingMode}`);
      }
    }
  }

  public log(
    level: LogLevel,
    commitId: string | null,
    message: string,
    metadata: object
  ): void {
    this.localLogger(level, message, metadata);
    if (level === LogLevel.Warn || level === LogLevel.Error) {
      this.remoteLogger.log(LogType.SdkMessage, level, commitId, message, {
        ...metadata,
        sdkVersion,
      });
    }
  }

  public flush(traceId: string): Promise<void> {
    return this.remoteLogger.flush(traceId);
  }
}

function getCreateLogsFunction(tracedFetch: TracedFetch, logsUrl: string) {
  return async (traceId: string, input: CreateLogsInput) => {
    const bodyJson = JSON.stringify(input);
    const bodyBlob = new Blob([bodyJson]);

    const response = await tracedFetch(traceId, logsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      },
      body: bodyJson,
      // Only use keepalive if the request is smaller than the maximum
      // allowed size with keepalive enabled.
      keepalive: bodyBlob.size < fetchMaxKeepAliveRequestSizeBytes,
    });

    return response.json();
  };
}

function wrapLocalLogger(
  loggerId: string,
  localLogger: LocalLogger | undefined
): LocalLogger {
  return (level: LogLevel, message: string, metadata: object) => {
    if (localLogger) {
      localLogger(level, message, { sdkVersion, loggerId, ...metadata });
      return;
    }

    if (level === LogLevel.Debug) {
      return;
    }

    toConsoleFunction(level)(...getLocalLogArguments(message, metadata));
  };
}
