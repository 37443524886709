import {
  DbAssignmentWithNullableEntries,
  EventTypeMap,
  Expression,
  FeatureMap,
  HashResponseBody,
  InitData,
  SplitMap,
  Value,
} from "@hypertune/sdk/src/shared/types";
import { z } from "zod";
import { voidGraphqlScalarTypeName } from "./constants";

export const graphqlScalarTypeNames = [
  voidGraphqlScalarTypeName,
  "Boolean",
  "Int",
  "Float",
  "String",
] as const;

export type GraphQLScalarTypeName = (typeof graphqlScalarTypeNames)[number];

export type DbJoinableBusiness = {
  id: number;
  name: string;
  member_count: number;
};

export type DbFunnelSegmentData = {
  breakdown_path_values_list: DbFeaturePathValues[];
  assignment_list: DbAssignmentWithNullableEntries[];
  unique_count: number;
  total_count: number;
};

export type DbArmReward = {
  arm_id: string;
  exposure_count: number;
  reward_sum: number;
};

export type DbArmRewardMap = { [armId: string]: DbArmReward };

export type DbFeaturePathValues = {
  [ssFeaturePath: string]: Value;
};

export type FunnelStep =
  | {
      type: "FunnelEventStep";
      eventObjectTypeName: string;
      unitIdPayloadPath: string[];
      breakdownPayloadPaths: string[][];
    }
  | {
      type: "FunnelExposureStep";
      splitId: string;
      unitId:
        | { type: "unitId" }
        | { type: "payloadPath"; payloadPath: string[] };
      dimensionIds: { [dimensionId: string]: true };
      breakdownPayloadPaths: string[][];
    };

export type FeaturePath = {
  featureId: string;
  jsonPath: (string | number)[];
};

export type DbVercelConnection = {
  projectId: number | null;
  vercelOwnerId: string | null;
  vercelEdgeConfigStoreId: string | null;
};

export type VercelEdgeConfigStore = {
  id?: string;
  createdAt?: number;
  ownerId?: string;
  slug?: string;
  updatedAt?: number;
  digest?: string;
  sizeInBytes: number;
  itemCount: number;
};

export type VercelEdgeConfigStoreWithTokens = VercelEdgeConfigStore & {
  tokens: VercelEdgeConfigStoreToken[];
};

export type VercelEdgeConfigStoreToken = {
  // createdAt: number;
  // edgeConfigId: string;
  id: string;
  // label: string;
  token: string;
};

export type AnalyticsSchema = {
  splits: SplitMap;
  eventTypes: EventTypeMap;
  features: FeatureMap;
};

export type Implementation = AnalyticsSchema & {
  expression: Expression;
};

export type ProjectMetadata = {
  generatedClient?: boolean;
  evaluatedFlags?: boolean;
};

export const projectTokenScopes = z.enum([
  "all",
  "query",
  "logs",
  "codegen",
  "flagDefinitions",
]);

export const projectTokenScopesArray = z.array(projectTokenScopes);

export type ProjectTokenScope = z.infer<typeof projectTokenScopes>;

export const projectTokenMapSchema = z.record(
  z.object({ scopes: z.array(projectTokenScopes) })
);

export type ProjectTokenMap = z.infer<typeof projectTokenMapSchema>;

export type ProjectTokenMapWithMeta = {
  [token: string]: {
    scopes: ProjectTokenScope[];
    name: string;
    createdAt: string;
  };
};

export const updateActiveCommitRequestBody = z.object({
  tokenMap: projectTokenMapSchema,
  branch: z.string(),
  businessId: z.number(),
  projectId: z.number(),
  commitId: z.number(),
  hash: z.string(),
  schemaCode: z.string(),
  expressionJson: z.string(),
  splitsJson: z.string(),
  eventTypesJson: z.string(),
  featuresJson: z.string(),
  configJson: z.string(),
});

export type UpdateActiveCommitBody = z.infer<
  typeof updateActiveCommitRequestBody
>;

// TODO: delete legacy types below when everyone is using sdkVersion >= 2.0.0

export type LegacyHashData = HashResponseBody & {
  commitHash: string;
};

export type LegacyInitData = InitData & {
  commitHash: string;
  eventTypes: EventTypeMap;
  initLogId: number;
  sdkConfig: {
    hashPollInterval: number;
    flushLogsInterval: number;
    maxLogsPerFlush: number;
  };
};
