import pRetry from "p-retry";
import {
  nullThrows,
  prefixError,
  reduce,
  InitData,
  Expression,
  Query,
  Value,
  ObjectValueWithVariables,
  UpdateListener,
  ReductionLogs,
  DehydratedState,
  DeepPartial,
  hash,
  stableStringify,
  LogLevel,
  uniqueId,
  InitDataProvider,
  ObjectValue,
  getSplitsAndCommitConfigForExpression,
  GetInitDataHashFunction,
  GetInitDataFunction,
} from "../shared";
import Logger from "./Logger";
import { isBrowser } from "./environment";
import LRUCache from "../shared/helpers/LRUCache";
import getMetadata from "../shared/helpers/getMetadata";

/** @internal: Not part of the Hypertune public API */
export default class Context {
  protected readonly initDataProvider: InitDataProvider | null;
  protected readonly initIntervalMs: number;
  protected readonly query: Query<ObjectValueWithVariables>;
  protected readonly queryCode: string;
  protected variableValues: ObjectValue;
  protected readonly updateListeners: Map<UpdateListener, boolean>;
  // @internal
  public readonly logger: Logger;
  // @internal
  public shouldClose = false;
  // @internal
  public initData: InitData | null;
  // @internal
  public lastDataProviderInitTime: number | null;
  // @internal
  public readonly getFieldCache: LRUCache<Expression> | null;
  // @internal
  public readonly getItemsCache: LRUCache<Expression[]> | null;
  // @internal
  public readonly evaluateCache: LRUCache<{
    value: Value;
    logs: ReductionLogs;
  }> | null;
  // @internal
  public override: object | null;

  // eslint-disable-next-line max-params
  constructor({
    initData,
    initDataProvider,
    initIntervalMs,
    query,
    queryCode,
    variableValues,
    logger,
    loggerFlushIntervalMs,
    cacheSize,
    override,
  }: {
    initData: InitData | null;
    initDataProvider: InitDataProvider | null;
    initIntervalMs: number;
    query: Query<ObjectValueWithVariables>;
    queryCode: string;
    variableValues: ObjectValue;
    logger: Logger;
    loggerFlushIntervalMs: number;
    cacheSize: number;
    override: object | null;
  }) {
    this.initDataProvider = initDataProvider;
    this.initIntervalMs = initIntervalMs;
    this.query = query;
    this.queryCode = queryCode;
    this.variableValues = variableValues;
    this.updateListeners = new Map();

    this.logger = logger;
    this.initData = null;
    this.lastDataProviderInitTime = null;
    this.getFieldCache = null;
    this.getItemsCache = null;
    this.evaluateCache = null;
    this.override = override;

    if (cacheSize > 0) {
      this.getFieldCache = new LRUCache(cacheSize);
      this.getItemsCache = new LRUCache(cacheSize);
      this.evaluateCache = new LRUCache(cacheSize);
    }
    const traceId = uniqueId();

    if (initData) {
      this.initFromData(traceId, initData);
    }
    this.initAndStartIntervals(loggerFlushIntervalMs);

    if (isBrowser) {
      window.addEventListener("beforeunload", async () => {
        if (this.shouldClose) {
          return;
        }
        await this.logger.flush(/* traceId */ uniqueId());
      });
    }
  }

  // @internal
  initFromData(traceId: string, initData: InitData | null): void {
    const initSourceName = "local data";
    if (!initData) {
      this.log(
        traceId,
        LogLevel.Info,
        `Not initializing from ${initSourceName} as it is null.`
      );
      return;
    }
    this.log(traceId, LogLevel.Info, `Initializing from ${initSourceName}...`);
    this.init(traceId, initSourceName, initData);
  }

  private init(
    traceId: string,
    initSourceName: string,
    newInitData: InitData
  ): void {
    try {
      const currentCommitId = this.initData?.commitId ?? -1;

      if (newInitData.commitId < currentCommitId) {
        this.log(
          traceId,
          LogLevel.Info,
          `Skipped initialization from ${initSourceName} as commit with id "${newInitData.commitId}" isn't newer than "${currentCommitId}".`
        );
        return;
      }
      if (
        newInitData.commitId === currentCommitId &&
        newInitData.hash === this.initData?.hash
      ) {
        // Silently skip initialization if we get the same commit and hash.
        // We don't want to log anything as this will happen regularly for
        // init data providers that only provide the full data and not just hash.
        return;
      }

      // If initializing from Hypertune Edge, the expression should already be
      // reduced given the query and variables. If initializing from the
      // fallback, it should already be reduced given the query but not the
      // variables. If initializing from Vercel Edge Config, it won't be reduced
      // at all. In all cases, we reduce the returned expression again anyway
      // given the query and variables.
      const reducedExpression = prefixError(
        () =>
          reduce(
            newInitData.splits,
            newInitData.commitConfig,
            this.query,
            this.variableValues,
            newInitData.reducedExpression,
            /* allowMissingVariables */ false
          ),
        "Reduction Error: "
      );

      this.initData = { ...newInitData, reducedExpression };
      this.log(
        traceId,
        LogLevel.Info,
        `Initialized successfully from ${initSourceName}.`
      );

      this.getFieldCache?.purge();
      this.getItemsCache?.purge();
      this.evaluateCache?.purge();

      this.notifyUpdateListeners();
    } catch (error) {
      this.log(
        traceId,
        LogLevel.Error,
        `Error initializing from ${initSourceName}.`,
        getMetadata(error)
      );
    }
  }

  // @internal
  initIfNeeded(traceId: string): Promise<void> {
    const { lastDataProviderInitTime, initDataProvider, initIntervalMs } = this;

    if (!initDataProvider) {
      this.log(
        traceId,
        LogLevel.Info,
        "Not initializing from data provider as it's null."
      );
      return Promise.resolve();
    }

    if (lastDataProviderInitTime) {
      const msSinceLastDataProviderInit = Date.now() - lastDataProviderInitTime;
      if (msSinceLastDataProviderInit < initIntervalMs) {
        this.log(
          traceId,
          LogLevel.Debug,
          `Not initializing from data provider as already did ${msSinceLastDataProviderInit}ms ago which is less than the update interval of ${initIntervalMs}ms.`
        );
        return Promise.resolve();
      }
    }

    return this.initFromDataProvider(traceId, initDataProvider);
  }

  private async initFromDataProvider(
    traceId: string,
    initDataProvider: InitDataProvider
  ): Promise<void> {
    const initSourceName = initDataProvider.getName();

    try {
      let newInitData: InitData | null = null;

      // If already initialized, first get the latest commit hash
      // to check if we need to update
      if (this.initData) {
        let latestInitDataHash: string;

        if (initDataProvider.getInitDataHash) {
          latestInitDataHash = await this.getLatestInitDataHash(
            traceId,
            initDataProvider.getInitDataHash.bind(initDataProvider)
          );
        } else {
          newInitData = await this.getLatestInitData(
            traceId,
            initSourceName,
            initDataProvider.getInitData.bind(initDataProvider)
          );
          latestInitDataHash = newInitData.hash;
        }

        if (this.initData.hash === latestInitDataHash) {
          this.log(traceId, LogLevel.Debug, "Commit hash is already latest.");
          return;
        }
        this.log(
          traceId,
          LogLevel.Info,
          `Commit hash (${this.initData.hash}) is not latest (${latestInitDataHash}).`
        );
      }

      this.log(
        traceId,
        LogLevel.Info,
        `Initializing from ${initSourceName}...`
      );
      if (!newInitData) {
        newInitData = await this.getLatestInitData(
          traceId,
          initSourceName,
          initDataProvider.getInitData.bind(initDataProvider)
        );
      }

      this.init(traceId, initSourceName, newInitData);
      this.lastDataProviderInitTime = Date.now();
    } catch (error) {
      this.log(
        traceId,
        LogLevel.Error,
        `All attempts to initialize from ${initSourceName} failed.`,
        getMetadata(error)
      );
    }
  }

  private async getLatestInitDataHash(
    traceId: string,
    getInitDataHash: GetInitDataHashFunction
  ): Promise<string> {
    this.log(traceId, LogLevel.Debug, "Getting latest commit hash...");

    const latestInitDataHash = await pRetry(
      (attemptNumber) => {
        this.log(
          traceId,
          LogLevel.Debug,
          `Attempt ${attemptNumber} to get latest commit hash...`
        );
        return getInitDataHash({
          traceId,
          queryCode: this.queryCode,
          variableValues: this.variableValues,
        });
      },
      {
        retries: 10,
        maxTimeout: 6000,
        onFailedAttempt: (error) => {
          this.log(
            traceId,
            LogLevel.Error,
            `Attempt ${error.attemptNumber} to get latest commit hash failed. There are ${error.retriesLeft} retries left.`,
            getMetadata(error)
          );
          if (this.shouldClose) {
            throw new pRetry.AbortError(
              `Stopped trying to get latest commit hash.`
            );
          }
        },
      }
    );

    return latestInitDataHash;
  }

  private getLatestInitData(
    traceId: string,
    initSourceName: string,
    getInitData: GetInitDataFunction
  ): Promise<InitData> {
    return pRetry(
      (attemptNumber) => {
        this.log(
          traceId,
          LogLevel.Debug,
          `Attempt ${attemptNumber} to initialize from ${initSourceName}...`
        );
        return getInitData({
          traceId,
          queryCode: this.queryCode,
          variableValues: this.variableValues,
        });
      },
      {
        retries: 10,
        maxTimeout: 6000,
        onFailedAttempt: (error) => {
          this.log(
            traceId,
            LogLevel.Error,
            `Attempt ${error.attemptNumber} to initialize from ${initSourceName} failed. There are ${error.retriesLeft} retries left.`,
            getMetadata(error)
          );
          if (this.shouldClose) {
            throw new pRetry.AbortError(
              `Stopped trying to initialize from ${initSourceName}.`
            );
          }
        },
      }
    );
  }

  private initAndStartIntervals(loggerFlushIntervalMs: number): void {
    if (!this.initDataProvider || this.initIntervalMs <= 0) {
      this.log(/* traceId */ "", LogLevel.Info, "Not polling for updates.");
    }
    if (this.initDataProvider) {
      // eslint-disable-next-line func-style
      const update = (): void => {
        const traceId = uniqueId();

        this.initIfNeeded(traceId)
          .catch((error) =>
            this.log(
              traceId,
              LogLevel.Error,
              "Error updating from data provider.",
              getMetadata(error)
            )
          )
          .finally(() => {
            if (this.shouldClose) {
              this.log(
                /* traceId */ "",
                LogLevel.Debug,
                "Stopped getting updates from the data provider."
              );
              return;
            }
            if (this.initIntervalMs > 0) {
              setTimeout(update, this.initIntervalMs);
            }
          });
      };
      update();
    }

    // eslint-disable-next-line @hypertune/prefer-early-returns
    if (loggerFlushIntervalMs > 0) {
      // eslint-disable-next-line func-style
      const flushLogQueue = (): void => {
        setTimeout(async () => {
          if (this.shouldClose) {
            this.log(
              /* traceId */ "",
              LogLevel.Debug,
              "Stopped flushing log queue."
            );
            return;
          }

          await this.logger.flush(/* traceId */ uniqueId());

          flushLogQueue();
        }, loggerFlushIntervalMs);
      };
      flushLogQueue();
    } else {
      this.log(
        /* traceId */ "",
        LogLevel.Info,
        "Not automatically flushing logs."
      );
    }
  }

  // @internal
  getStateHash(): string {
    const initDataHash = this.initData?.hash ?? null;
    const ssOverride = stableStringify(this.override);
    return hash(`${initDataHash}/${ssOverride}`).toString();
  }

  // @internal
  addUpdateListener(listener: UpdateListener): void {
    this.updateListeners.set(listener, true);
  }

  // @internal
  removeUpdateListener(listener: UpdateListener): void {
    this.updateListeners.delete(listener);
  }

  private notifyUpdateListeners(): void {
    const stateHash = this.getStateHash();
    this.updateListeners.forEach((_, listener) => {
      listener(stateHash);
    });
  }

  // @internal
  setOverride<Override extends object>(
    traceId: string,
    override: DeepPartial<Override> | null
  ): void {
    if (stableStringify(override) === stableStringify(this.override)) {
      this.log(
        traceId,
        LogLevel.Info,
        "Skipped setting override as it's equal to the one already set."
      );
      return;
    }

    this.override = override;
    this.log(traceId, LogLevel.Info, "Set override.", { override });
    this.notifyUpdateListeners();
  }

  // @internal
  dehydrate<Override extends object, VariableValues extends ObjectValue>(
    query?: Query<ObjectValueWithVariables>,
    variableValues?: VariableValues
  ): DehydratedState<Override, VariableValues> | null {
    // Create a copy of the init data as we are modifying it below.
    const initData = this.initData ? { ...this.initData } : null;
    if (!initData) {
      return null;
    }
    const { override } = this;

    const dehydrateQuery = query ?? this.query;
    const dehydrateQueryVariableValues: VariableValues =
      variableValues ?? (this.variableValues as VariableValues);

    initData.reducedExpression = prefixError(
      () =>
        reduce(
          initData.splits,
          initData.commitConfig,
          dehydrateQuery,
          dehydrateQueryVariableValues,
          initData.reducedExpression,
          /* allowMissingVariables */ false
        ),
      "Reduction Error: "
    );
    const { splits, commitConfig } = getSplitsAndCommitConfigForExpression(
      initData.reducedExpression,
      initData.splits,
      initData.commitConfig
    );
    initData.splits = splits;
    initData.commitConfig = commitConfig;

    return {
      initData,
      override: override as DeepPartial<Override>,
      variableValues: dehydrateQueryVariableValues,
    };
  }

  // @internal
  hydrate<Override extends object, VariableValues extends ObjectValue>(
    traceId: string,
    dehydratedState: DehydratedState<Override, VariableValues>
  ): void {
    this.log(traceId, LogLevel.Info, "Hydrating...");
    const { initData, override, variableValues } = dehydratedState;

    if (initData && variableValues) {
      this.variableValues = variableValues;
    }
    this.initFromData(traceId, initData);
    this.setOverride<Override>(traceId, override);
  }

  // @internal
  reduce(
    query: Query<ObjectValueWithVariables> | null,
    expression: Expression
  ): Expression {
    const { splits, commitConfig } = nullThrows(
      this.initData,
      "No init data so cannot reduce expression."
    );

    return prefixError(
      () =>
        reduce(
          splits,
          commitConfig,
          query,
          /* variableValues */ {},
          expression,
          /* allowMissingVariables */ false
        ),
      "Reduction error: "
    );
  }

  private log(
    traceId: string,
    level: LogLevel,
    message: string,
    metadata: object = {}
  ): void {
    this.logger.log(
      level,
      this.initData?.commitId.toString() ?? null,
      message,
      { traceId, ...metadata }
    );
  }
}
